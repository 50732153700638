export const isLogin = function () {
    const token = localStorage.getItem('stable_page');
    return !!token;
};


export const generateRandomCode = (n: number) => {
    let str = ''
    for (let i = 0; i < n; i++) {
        str += Math.floor(Math.random() * 10)
    }
    return str
}