import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import React from 'react';
import {RouteIf} from "./RouteIf";
import LoginPage from "@/presentation/pages/login/LoginPage";
import {isLogin} from "@/presentation/helpers/auth";
import MakeImagePage from "@/presentation/pages/makeImage/MakeImagePage";


const Router: React.FC = () => (
    <RecoilRoot>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    isLogin() ?
                        <RouteIf option={true}>
                            <MakeImagePage/>
                        </RouteIf>
                        :
                        <LoginPage/>
                }/>
                {/*<Route path="/generate" element={*/}
                {/*    <RouteIf option={false}>*/}
                {/*        <MakeImagePage/>*/}
                {/*    </RouteIf>*/}
                {/*}/>*/}

            </Routes>
        </BrowserRouter>
    </RecoilRoot>
)

export default Router;