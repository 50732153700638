export const getOptionKrTxt = (txt: string) => {
    switch (txt){
        case 'man': return '남자';
        case 'female': return '여자';
        case 'black hair': return '검은색 머리';
        case 'brown hair': return '갈색 머리';
        case 'blond hair': return '금발 머리';
        case 'white hair': return '하얀 머리';
        case 'whole body': return '전신';
        case 'bust': return '상반신';
        case 'head': return '머리';
        case 'fantasy': return '판타지';
        case 'realistic': return '리얼리스틱';
        case 'SF': return 'SF';
        case 'animation': return '애니메이션';
        case 'standing': return '서있는';
        case 'sitting': return '앉아있는';
        case 'lying down': return '누워있는';
        case 'eye contact': return '아이컨택';
        case 'cute': return '귀여운';
        case 'angry': return '화난';
        case 'smiling': return '웃는';
        case 'sunny': return '맑음';
        case 'rain': return '비';
        case 'snow': return '눈';
        case 'inside': return '실내';
        case 'outside': return '실외';
        case 'afternoon': return '낮';
        case 'night': return '밤';
        case 'cozy': return '포근한';
        case 'loud': return '시끄러운';
        case 'adult': return '어른';
        case 'student': return '학생';
        case 'kid': return '아이';
        case 'baby': return '아기';










    }
}