
import {notificationAlert} from "@/presentation/helpers/alert";
import {PermissionError} from "@/domain/errors/permission-error";
import {BaseError} from "@/domain/errors";

interface PrError {
    type: string,
    error: any,
    alert?: boolean
}

export const presentationError = (params: PrError) => {
    if (params.error instanceof BaseError) {
        if (params.alert) {
            notificationAlert('오류', params.error.message)
            if (params.error.errorCode === 401) {
                handleClickLogout();
            }
        }
    } else if (params.error instanceof PermissionError) {
        window.history.back();
    } else {
        // sendErrorSentry(params.error);
        if (params.alert) {
            if (params.error.message) {
                notificationAlert('오류', params.error.message);
            } else {
                notificationAlert('오류', `${params.type}에 문제가 발생하였습니다.`);
            }
        }
    }
}

export const handleClickLogout = async () => {
    notificationAlert('알림', '로그인을 재시도해 주세요.');
    setTimeout(() => {
        localStorage.removeItem('stable_page');
        window.location.href = '/';
    }, 500)

}