import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/presentation/assets/index.css';
import Router from "@/main/routes/router";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Router/>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

