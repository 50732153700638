import React, {useState} from "react";
import mainLogo from "@/presentation/assets/image/logo.png";
import LoaderButton from "@/presentation/components/button/LoaderButton";
import axios from "axios";
import {RotatingTriangles} from "react-loader-spinner";
import {presentationError} from "@/presentation/errors/presentationError";
import {notificationAlert} from "@/presentation/helpers/alert";
import {getOptionKrTxt} from "@/presentation/pages/makeImage/constant";
import Swal from "sweetalert2";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';


interface Options {
    idx: number
    option: string
}


const initOptions = [{
    option: '',
    idx: -1
}, {
    option: '',
    idx: -1
}, {
    option: '',
    idx: -1
}, {
    option: '',
    idx: -1
}, {
    option: '',
    idx: -1
},
    {
        option: '',
        idx: -1
    }];

const initPlaceOptions = [{
    option: '',
    idx: -1
}, {
    option: '',
    idx: -1
}, {
    option: '',
    idx: -1
}, {
    option: '',
    idx: -1
}]

const MakeImagePage: React.FC = () => {

    const [keyword, setKeyword] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [modalImg, setModalImg] = useState<string>('');
    const [resultImg, setResultImg] = useState<string>('');
    const [resultImg2, setResultImg2] = useState<string>('');
    const [type, setType] = useState<'character' | 'place' | ''>('');
    const [options, setOptions] = useState<Options[]>(initOptions);
    const [placeOptions, setplaceOptions] = useState<Options[]>(initPlaceOptions);
    const [open, setOpen] = useState<boolean>(false);
    const [hoverImg, setHoverImg] = useState<boolean>(false);
    const getStableImg = async () => {
        try {
            setLoader(true);
            setResultImg('');
            // const result = await axios.get(`https://stable-diffusion.datacredit.kr/img/gen/1?text=${keyword}`);
            let prompt = type === 'character' ? [...options] : [...placeOptions];
            prompt.unshift({
                option: type,
                idx: 0
            });
            prompt = prompt.filter(p => p.option);
            const result = await axios.post('https://st-edu.datacredit.kr/item/create', {
                "content": keyword,
                "prompt": prompt.map(p => p.option),
                "negativePrompt": []
            }, {
                headers: {
                    Authorization: `bearer ${localStorage.getItem('access_token')}`
                }
            });


            if (result) {
                if (result.data.filename) {
                    setResultImg(`https://st-edu.datacredit.kr/item/${result.data.filename}`);
                }
            } else {
                notificationAlert('알림', '이미지 생성에 문제가 발생하였습니다.');
            }
            setLoader(false);
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                notificationAlert('알림', '부정적인 단어가 포함되어 있습니다.');
            } else {
                presentationError({
                    error: err,
                    type: '이미지 생성',
                    alert: true
                });
            }
            setLoader(false);
        }
    }

    const handleClickOption = (idx: number, val: string) => {
        const data = {
            option: val,
            idx: idx - 1
        }
        if (type === 'character') {
            let newOptions = options;
            newOptions[idx - 1] = data;
            setOptions([...newOptions]);
        } else {
            let newOptions = placeOptions;
            newOptions[idx - 1] = data;
            setplaceOptions([...newOptions]);
        }
    }


    const delOption = (idx: number) => {
        if (type === 'character') {
            let newOptions = options;
            newOptions[idx] = {
                option: '',
                idx: -1
            };
            setOptions([...newOptions]);
        } else {
            let newOptions = placeOptions;
            newOptions[idx] = {
                option: '',
                idx: -1
            };
            setplaceOptions([...newOptions]);
        }
    }

    const  downloadImage = (imageLink: string) => {
        fetch(imageLink)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = "test.png";
                link.click();
                URL.revokeObjectURL(url);
            });
    }

    const downloadFile = (url: string) => {
        axios({
            url: decodeURIComponent(url),
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `stable-diffusion.png`)
            document.body.appendChild(link)
            link.click()
        })
    };

    const sendMail = async () => {
        try {
            setOpen(false);
            const {value: email} = await Swal.fire({
                title: `이메일`,
                text: '이메일 주소를 입력하여 주세요.',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: '전송',
                cancelButtonText: '취소'
            })
            if (email) {
                const result = await axios.post('https://st-edu.datacredit.kr/item/mail', {
                    email: email,
                    img: resultImg
                }, {
                    headers: {
                        "Authorization": `bearer ${localStorage.getItem('access_token')}`
                    }
                })

                if (result) {
                    notificationAlert('알림', '메일 전송이 완료되었습니다.');
                }
            }
        } catch (err) {
            notificationAlert('오류', '이메일 전송에 문제가 발생하였습니다.');
        }
    }


    const handleClickImg = (link: string) => {
        setModalImg(link);
        setOpen(true);
    }


    const handleLogout = async () => {
        try {
            const {value: password} = await Swal.fire({
                title: `로그아웃`,
                text: '비밀번호를 입력하여 주세요.',
                input: 'password',
                showCancelButton: true,
                confirmButtonText: '확인',
                cancelButtonText: '취소'
            })
            if (password) {
                // const result = await axios.post('https://st-edu.datacredit.kr/auth/logout', {
                //     password: password
                // }, {
                //     headers: {
                //         "Authorization": `bearer ${localStorage.getItem('access_token')}`
                //     }
                // })
                if (password === process.env.REACT_APP_LOGOUT_PWD){
                    localStorage.removeItem('stable_page');
                    localStorage.removeItem('access_token');
                    window.location.href = '/';
                }else {
                    notificationAlert('알림','잘못된 비밀번호 입니다.');
                }
            }
        } catch (err) {
            notificationAlert('오류', '잘못된 비밀번호 입니다.');
        }
    }

    return (
        <div
            className={`bg-[#F9FAFD] w-[100vw] min-h-[100vh] relative ${loader ? 'overflow-hidden' : 'overflow-scroll'}`}>
            {
                loader && <div
                    className={'bg-[#000000a6] absolute left-0 bottom-0 top-0 w-full min-h-screen h-full z-10 flex flex-col justify-center items-center'}>
                    <RotatingTriangles
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="rotating-triangels-loading"
                        wrapperStyle={{}}
                        wrapperClass="rotating-triangels-wrapper"
                    />
                    <h1 className={'text-white block text-[2rem] animate-bounce'}>이미지 생성중...</h1>
                </div>
            }
            <div className={'bg-white border-b'}>
                <div className={'max-w-[1400px] mx-auto w-full py-1 flex flex-row justify-between items-center px-4'}>
                    <img className={'w-[150px]'} src={mainLogo} alt={'main_logo'}/>
                    <button
                        onClick={handleLogout}
                        className={'bg-[#8071FC] text-[0.875rem] rounded-[6px] p-5 text-white font-semibold hover:opacity-50'}>로그아웃
                    </button>
                </div>
            </div>
            <div className={'max-w-[1400px] px-4 mx-auto'}>
                <div>
                    <h1 className={'text-5xl text-[#2D75B6] font-bold mt-8'}>AI에게 그리고 싶은 그림을 설명해 주세요</h1>
                </div>
                <div className={'flex flex-row justify-between mt-8'}>
                    <textarea
                        value={keyword}
                        className={'min-h-[120px] bg-white rounded-[6px] flex-1 shadow-md mr-3 p-2 outline-[#8071FC]'}
                        onChange={event => setKeyword(event.target.value)}
                        placeholder={'생성하고 싶은 이미지 키워드 또는 설명을 입력하여 주세요.'}
                    />
                    <LoaderButton
                        className={'max-h-[120px] bg-[#8071FC] rounded-[6px] w-[120px] text-white font-semibold hover:opacity-50'}
                        onClick={getStableImg}
                        loader={loader}>
                        그림 그리기
                    </LoaderButton>
                </div>
                <div className={'py-6'}>
                    <h1 className={'text-md  text-center font-semibold text-[#8071FC] mt-5'}><LightbulbIcon/>이런걸 그려볼 수
                        있어요!</h1>
                    <div className="flex flex-wrap justify-center gap-4 py-4">
                        <button
                            onClick={event => setKeyword('원시시대 공룡 사진')}
                            className="mb-2 text-center items-center py-3 px-6 text-[1rem] text-[#8071FC] font-semibold bg-white shadow-md rounded-[33px] hover:opacity-50"
                        >
                            원시시대 공룡 사진을 그려줘
                        </button>
                        <button
                            onClick={event => setKeyword('로블록스 배경화면')}
                            className="mb-2 text-center items-center p-3 text-[1rem] text-[#8071FC] font-semibold bg-white shadow-md rounded-[33px] hover:opacity-50"
                        >
                            로블록스 배경화면을 그려줘
                        </button>
                        <button
                            onClick={event => setKeyword('마인크래프트 월드 풍경 사진')}
                            className="mb-2 text-center items-center p-3 text-[1rem] text-[#8071FC] font-semibold bg-white shadow-md rounded-[33px] hover:opacity-50"
                        >
                            마인크래프트 월드 풍경 사진을 그려줘
                        </button>
                        <button
                            onClick={event => setKeyword('우주복을 입은 강아지')}
                            className="mb-2 text-center items-center p-3 text-[1rem] text-[#8071FC] font-semibold bg-white shadow-md rounded-[33px] hover:opacity-50"
                        >
                            우주복을 입은 강아지을 그려줘
                        </button>
                    </div>

                </div>
                <div className={'flex-row  flex justify-between pb-[2rem]'}>
                    <div className={'w-[39%] bg-white shadow-md rounded-[6px] min-h-[300px]'}>
                        <div
                            className={'p-2 min-h-[58px] mt-2 flex flex-row flex-wrap gap-[5px] bg-[#F9FAFD] w-[95%] mx-auto rounded-[35px] shadow-md'}>
                            {type &&
                                <div
                                    className={'[word-wrap: break-word] my-[5px] flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#8071FC] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-white shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none '}>
                                    {type === 'character' ? '캐릭터' : '사물'}
                                    <span
                                        onClick={() => {
                                            setType('');
                                            if (type === 'character') {
                                                setOptions(initOptions);
                                            } else {
                                                setplaceOptions(initPlaceOptions);
                                            }
                                        }}
                                        className="float-right w-4 cursor-pointer pl-[8px] text-[20px] text-white hover:opacity-[.53] transition-all duration-200 ease-in-out ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-3 w-3">
                                          <path
                                              strokeLinecap={'round'}
                                              strokeLinejoin={'round'}
                                              d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                      </span>
                                </div>
                            }
                            {
                                type === "character" ?
                                    options.filter(p => p && p.option).map((p, idx) => <div
                                        className={'[word-wrap: break-word] my-[5px] flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#8071FC] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-white shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none '}>
                                        {getOptionKrTxt(p.option)}
                                        <span
                                            onClick={() => delOption(p.idx)}
                                            className="float-right w-4 cursor-pointer pl-[8px] text-[20px] text-white hover:opacity-[.53] transition-all duration-200 ease-in-out ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-3 w-3">
                                          <path
                                              strokeLinecap={'round'}
                                              strokeLinejoin={'round'}
                                              d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                      </span>
                                    </div>)
                                    :
                                    placeOptions.filter(p => p && p.option).map((p, idx) => <div
                                        className={'[word-wrap: break-word] my-[5px] flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#8071FC] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-white shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none '}>
                                        {getOptionKrTxt(p.option)}
                                        <span
                                            onClick={() => delOption(p.idx)}
                                            className="float-right w-4 cursor-pointer pl-[8px] text-[20px] text-white hover:opacity-[.53] transition-all duration-200 ease-in-out ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-3 w-3">
                                          <path
                                              strokeLinecap={'round'}
                                              strokeLinejoin={'round'}
                                              d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                      </span>
                                    </div>)

                            }
                        </div>
                        <div className={'flex flex-col p-4'}>
                            <div className={'mb-4'}>
                                <p className={'mb-1'}>종류</p>
                                <div className={'flex gap-4'}>
                                    <button
                                        onClick={() => {
                                            setType('character');
                                            setplaceOptions(initPlaceOptions);
                                        }}
                                        className={`border-2 ${type === 'character' ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'} border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2`}>캐릭터
                                    </button>
                                    <button
                                        onClick={() => {
                                            setType('place');
                                            setOptions(initOptions);
                                        }}
                                        className={`border-2 ${type === 'place' ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'} border-[#8071FC] text-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2`}>사물
                                    </button>
                                </div>
                            </div>
                            {type &&
                                <>
                                    {type === 'character' ?
                                        <>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>성별</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(1, 'man')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${options.some(item => item.option === 'man') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>남자
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(1, 'female')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${options.some(item => item.option === 'female') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>여자
                                                    </button>
                                                </div>
                                            </div>

                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>머리색</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(2, 'black hair')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'black hair') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>검은색
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(2, 'brown hair')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'brown hair') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>갈색
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(2, 'blond hair')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'blond hair') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>금발
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(2, 'white hair')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'white hair') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>흰색
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>구도</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(3, 'whole body')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-[33%] py-2 ${options.some(item => item.option === 'whole body') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>전신
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(3, 'bust')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-[33%] py-2 ${options.some(item => item.option === 'bust') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>상반신
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(3, 'head')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-[33%] py-2 ${options.some(item => item.option === 'head') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>머리
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>테마</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(4, 'fantasy')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'fantasy') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>판타지
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(4, 'realistic')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'realistic') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>리얼리스틱
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(4, 'SF')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'SF') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>SF
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(4, 'animation')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'animation') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>애니메이션
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>감정</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(5, 'cute')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${options.some(item => item.option === 'cute') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>귀여운
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(5, 'angry')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${options.some(item => item.option === 'angry') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>화난
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(5, 'smiling')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${options.some(item => item.option === 'smiling') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>웃는
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>연령</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(6, 'adult')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'adult') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>어른
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(6, 'student')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'student') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>학생
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(6, 'kid')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'kid') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>아이
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(6, 'baby')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/4 py-2 ${options.some(item => item.option === 'baby') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>아기
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>날씨</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(1, 'sunny')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-[33%] py-2 ${placeOptions.some(item => item.option === 'sunny') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>맑음
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(1, 'rain')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-[33%] py-2 ${placeOptions.some(item => item.option === 'rain') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>비
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(1, 'snow')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-[33%] py-2 ${placeOptions.some(item => item.option === 'snow') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>눈
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>공간</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(2, 'inside')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${placeOptions.some(item => item.option === 'inside') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>실내
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(2, 'outside')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${placeOptions.some(item => item.option === 'outside') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>실외
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>시간</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(3, 'afternoon')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${placeOptions.some(item => item.option === 'afternoon') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>낮
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(3, 'night')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${placeOptions.some(item => item.option === 'night') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>밤
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'mb-4'}>
                                                <p className={'mb-3'}>분위기</p>
                                                <div className={'flex gap-4'}>
                                                    <button
                                                        onClick={() => handleClickOption(4, 'cozy')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${placeOptions.some(item => item.option === 'cozy') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>포근한
                                                    </button>
                                                    <button
                                                        onClick={() => handleClickOption(4, 'loud')}
                                                        className={`border-2 border-[#8071FC] rounded-[6px] hover:opacity-50 w-1/2 py-2 ${placeOptions.some(item => item.option === 'loud') ? 'text-white bg-[#8071FC]' : 'text-[#8071FC]'}`}>시끄러운
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div
                        className={'w-[59%] bg-white shadow-md rounded-[6px] min-h-[300px] flex flex-col items-center'}>
                        <div className={'px-3 pb-3 pt-5 font-semibold text-[1.5rem] text-center'}>
                            생성된 이미지
                        </div>
                        {resultImg &&
                            <div onMouseEnter={() => setHoverImg(true)} onMouseLeave={() => setHoverImg(false)}
                                 className={`m-3 relative flex flex-row justify-between items-center `}>
                                {hoverImg && <button onClick={() => handleClickImg(resultImg)}
                                                     className={'absolute z-20 right-6 top-6  rounded-[50%] p-2'}>
                                    <OpenInFullIcon className={'text-white'}/>
                                </button>}
                                {hoverImg && <button onClick={() => downloadFile(resultImg)}
                                                     className={'absolute z-20 right-6 bottom-6 rounded-[50%] p-2'}>
                                    <DownloadIcon className={'text-white'}/>
                                </button>}
                                {hoverImg && <button onClick={sendMail}
                                                     className={'absolute z-20 right-16 bottom-6 rounded-[50%] p-2'}>
                                    <EmailIcon className={'text-white'}/>
                                </button>}
                                {hoverImg && <div
                                    className={'p-2 bg-gradient-to-b from-black/80 to-gray-600/20 z-10 absolute w-full top-0 left-0 h-[80px]'}></div>}
                                {hoverImg && <div
                                    className={'p-2 bg-gradient-to-t from-black/80 to-gray-600/20 z-10 absolute w-full bottom-0 left-0 h-[80px]'}></div>}
                                <img className={`object-cover w-full `}
                                     alt={"result_img"}
                                     src={resultImg}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/*<div className={'max-w-[1400px] mx-auto'}>*/}
            {/*    <div className={'w-full flex justify-end mt-4'}>*/}
            {/*        <Link*/}
            {/*            className={'bg-[#8071FC] rounded-[6px] text-white font-semibold px-7 py-3 mr-3 hover:opacity-50'}*/}
            {/*            to={'/generate'}>다시하기</Link>*/}
            {/*        <Link className={'bg-[#B1B1B1] rounded-[6px] text-white font-semibold px-7 py-3 hover:opacity-50'}*/}
            {/*              to={'/generate'}>메인화면</Link>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogContent>
                    <div className={'relative'}>
                        <div className={'absolute right-2 top-2'}>
                            <button
                                onClick={() => setOpen(false)}
                                className={'p-4 bg-[#8071FC] rounded-[33px] text-white font-bold hover:opacity-50'}>
                                <CloseIcon/>
                            </button>
                        </div>
                        <img className={'w-full'} src={modalImg} alt={''}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className={'flex w-full justify-between flex-row px-[16px] pb-[1rem]'}>
                        <button
                            onClick={() => downloadImage(modalImg)}
                            className={'p-4 w-[47%] bg-[#8071FC] rounded-[6px]  text-white font-bold hover:opacity-50'}>
                            <DownloadIcon/>
                        </button>

                        <button
                            onClick={sendMail}
                            className={'p-4 w-[47%] bg-[#8071FC] rounded-[6px] text-white font-bold hover:opacity-50'}>
                            <EmailIcon/>
                        </button>
                    </div>
                </DialogActions>
            </Dialog>


        </div>
    )
}


export default MakeImagePage;
