import React, {useState} from "react";
import bg3 from '@/presentation/assets/image/미래교육테마파크_야경투시도.jpeg';
import logo from '@/presentation/assets/image/logo.png';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {generateRandomCode} from "@/presentation/helpers/auth";
import {presentationError} from "@/presentation/errors/presentationError";
import LoaderButton from "@/presentation/components/button/LoaderButton";

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [id, setId] = useState<string>('');
    const [pwd, setPwd] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const handleLogin = async () => {
        try {
            setLoader(true);
            const formData = new FormData()
            formData.set('username', id);
            formData.set('password', pwd);
            const result = await axios.post('https://st-edu.datacredit.kr/auth/login', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })

            if (result) {
                localStorage.setItem('stable_page', generateRandomCode(16));
                localStorage.setItem('access_token', result.data.access_token);
                setLoader(false);
                // navigate('/');
                // window.location.href = '/generate';
                window.location.reload();
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '로그인',
                alert: true
            });
            setLoader(false);
        }
    }


    return (
        <div className={"w-[100vw] h-[100vh] relative main_bg"}
             style={{
                 backgroundImage: `url(${bg3})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover'
             }}
        >
            {/*<div className={'absolute left-0 top-0 w-full h-full z-[-1]'}/>*/}
            <div className={'flex-col flex justify-between h-full relative'}>
                <div className={'flex-col flex '}>
                    <div className={'px-6 py-5 border-white'}>
                        <img className={'w-[150px]'} src={logo} alt={'main_logo'}/>
                    </div>
                    <div className={'pt-[5rem]'}>
                        <h1 className={'text-center font-bold text-[5rem] text-white tracking-widest'}>AI로 그림 만들기</h1>
                    </div>
                </div>
                <div className={'flex-col flex'}>
                    <div className={'px-8 pb-[5rem]'}>
                        <div className={'flex flex-row justify-end'}>
                            <div className={'pr-3 text-white font-semibold'}>
                                <span className={'block leading-[50px]'}>ID</span>
                                <span className={'block leading-[50px] mt-4'}>PASSWORD</span>
                            </div>
                            <div>
                                <input
                                    type={'text'}
                                    onChange={e => setId(e.target.value)}
                                    className={'block bg-[#EAEAEA] min-w-[400px] h-[50px] rounded-[6px] px-2 outline-[#8071FC]'}
                                    placeholder={'휴대폰 번호 또는 이메일을 입력하여 주세요.'}/>
                                <input
                                    type={'password'}
                                    onChange={e => setPwd(e.target.value)}
                                    className={'block mt-4 bg-[#EAEAEA] min-w-[400px] h-[50px] rounded-[6px] px-2 outline-[#8071FC]'}
                                    placeholder={'비밀번호를 입력하여 주세요.'}/>
                                <div className={'text-white font-semibold flex flex-row justify-start mt-4'}>
                                    {/*<Link className={'mr-5'} to={'/'}>비밀번호 찾기</Link>*/}
                                    {/*<Link to={'/'}>회원가입</Link>*/}
                                </div>
                            </div>
                            <LoaderButton
                                onClick={handleLogin}
                                loader={loader}
                                className={'bg-[#8071FC] text-white font-semibold ml-4 w-[120px] h-[116px] rounded-[6px] hover:opacity-50'}>로그인
                            </LoaderButton>
                        </div>
                    </div>

                    <div className={'py-4'}>
                        <h2 className={'text-white text-center text-[2rem] font-semibold'}>경상남도교육청</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default LoginPage;
